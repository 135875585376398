const items = new Set([
    'Air Knife',
    'Zorlin Shape',
    'Iga Knife',
    'Koga Knife',
    'Nagrarock',
    'Materia Blade',
    'Blood Sword',
    'Rune Blade',
    'Save the Queen',
    'Excalibur',
    'Ragnarok',
    'Chaos Blade',
    'Masamune',
    'Chirijiraden',
    'Slasher',
    'Faith Rod',
    'Dragon Rod',
    'Healing Staff',
    'Sage Staff',
    'Mace of Zeus',
    'Scorpion Tail',
    'Stone Gun',
    'Blaze Gun',
    'Glacier Gun',
    'Blast Gun',
    'Gastrafitis',
    'Yoichi Bow',
    'Perseus Bow',
    'Bloody Strings',
    'Fairy Harp',
    'Madlemgen',
    'Holy Lance',
    'Gungnir',
    'Dragon Whisker',
    'Whale Whisker',
    'Star Bag',
    'Ryozan Silk',
    'Venetian Shield',
    'Hero Shield',
    'Genji Shield',
    'Kaiser Plate',
    'Genji Helmet',
    'Grand Helmet',
    'Cachusha',
    'Barette',
    'Ribbon',
    'Black Hood',
    'Twist Headband',
    'Flash Hat',
    'Thief Hat',
    'Genji Armor',
    'Reflect Mail',
    'Maximillian',
    'Wizard Outfit',
    'Power Sleeve',
    'Secret Clothes',
    'Rubber Costume',
    'Wizard Robe',
    'Robe of Lords',
    'Genji Gauntlet',
    'Magic Gauntlet',
    'Bracer',
    'Cursed Ring',
    'Angel Ring',
    'Setiemson',
    'Chantage',
    'Serpentarius',
    'Vanish Mantle',
    'Salty Rage',
]);

const abilities = new Set([
    'Cure 4',
    'Raise',
    'Raise 2',
    'Holy',
    'Dark Holy',
    'Fire 4',
    'Bolt 4',
    'Ice 4',
    'Death',
    'Flare',
    'Demi 2',
    'Meteor',
    'Petrify',
    'Moogle',
    'Carbunkle',
    'Bahamut',
    'Zodiac',
    'Cyclops',
    'Kiyomori',
    'Kikuichimoji',
    'Chirijiraden',
    'Masamune',
    'Last Song',
    'Wiznaibus',
    'Nameless Dance',
    'Earth Slash',
    'Revive',
    'Purification',
    'Steal Heart',
    'Invitation',
    'Death Sentence',
    'Mimic Daravon',
    'Water Ball',
    'Hallowed Ground',
    'Ultima',
    'Stasis Sword',
    'Justice Sword',
    'Surging Sword',
    'Explosion Sword',
    'Night Sword',
    'Phoenix Down',
    'X-Potion',
    'Remedy',
    'Dragon Spirit',
    'Auto Potion',
    'Blade Grasp',
    'Hamedo',
    'Dual Wield',
    'Short Charge',
    'Move+3',
    'Jump+3',
    'Teleport 2',
    'Equip Shield',
    'Equip Sword',
    'Equip Knife',
    'Equip Bow',
    'Equip Polearm',
    'Equip Axe',
    'Equip Gun',
    'Doublehand',
    'Galaxy Stop',
    'Elixir',
    'Attack UP',
    'Magic Attack UP',
    'Martial Arts',
    'Lucavi Skill',
    'Limit',
    'Arm Aim',
    'Gil Taking',
    'Magic Barrier',
]);

export default {
    items,
    abilities,
};
